import { ComponentProps } from 'react'
import { Path, Svg } from 'ui-lib'

const RewardsIcon = (props?: ComponentProps<typeof Svg>) => {
  return (
    <Svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      marginTop="1px"
      {...props}
    >
      <Path
        d="M9.22815 2.19352C9.49482 1.49018 10.504 1.49018 10.7715 2.19352L12.4965 6.97185C12.5567 7.1275 12.6626 7.26125 12.8004 7.35543C12.9382 7.44961 13.1013 7.49979 13.2682 7.49935H17.5073C18.2907 7.49935 18.6323 8.47435 18.0165 8.95185L14.9998 11.666C14.8647 11.7699 14.7659 11.9139 14.7177 12.0774C14.6695 12.2409 14.6743 12.4154 14.7315 12.576L15.8332 17.2452C16.1015 17.9952 15.2332 18.6393 14.5765 18.1777L10.479 15.5777C10.3387 15.4791 10.1713 15.4261 9.99982 15.4261C9.82831 15.4261 9.66098 15.4791 9.52065 15.5777L5.42315 18.1777C4.76732 18.6393 3.89815 17.9943 4.16649 17.2452L5.26815 12.576C5.3253 12.4154 5.33012 12.2409 5.28191 12.0774C5.2337 11.9139 5.13495 11.7699 4.99982 11.666L1.98315 8.95185C1.36649 8.47435 1.70982 7.49935 2.49149 7.49935H6.73065C6.89756 7.4999 7.06071 7.44977 7.1985 7.35558C7.33629 7.26138 7.44224 7.12757 7.50232 6.97185L9.22732 2.19352H9.22815Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default RewardsIcon
