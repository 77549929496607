import {
  Alert as ChakraAlert,
  AlertDescription as ChakraAlertDescription,
  AlertProps as ChakraAlertProps,
  AlertTitle as ChakraAlertTitle,
  AlertTitleProps,
  CloseButton,
  createStylesContext,
  Flex,
  TextProps,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import React from 'react'
import CircleAlertFill from 'ui-lib/src/icons/CircleAlertFill'
import CircleCheckFill from 'ui-lib/src/icons/CircleCheckFill'
import CircleCloseFill from 'ui-lib/src/icons/CircleCloseFill'
import CircleInfoFill from 'ui-lib/src/icons/CircleInfoFill'
import isDefined from 'ui-lib/src/utils/data-shape'

export type AlertProps = Omit<ChakraAlertProps, 'variant' | 'size'> & {
  children: React.ReactNode
  variant?: 'positive' | 'negative' | 'warning' | 'info'
  onClose?: () => void
  size?: 'sm' | 'md'
  icon?: JSX.Element
}

export type AlertChildrenProps = TextProps & {
  children: string | React.ReactNode
}

const [StylesProvider, useStyles] = createStylesContext('Alert')

export const Alert = ({ children, variant = 'info', onClose, size, icon, ...props }: AlertProps) => {
  const styles = useMultiStyleConfig('Alert', { variant, size })
  const isChildrenArray = Array.isArray(children)

  const IconByVariant = {
    positive: <CircleCheckFill sx={styles.icon} />,
    negative: <CircleCloseFill sx={styles.icon} />,
    warning: <CircleAlertFill sx={styles.icon} />,
    info: <CircleInfoFill sx={styles.icon} />,
  }

  return (
    <ChakraAlert sx={styles.container} {...props} alignItems={isChildrenArray ? 'flex-start' : 'center'}>
      {isDefined(icon) ? icon : IconByVariant[variant]}
      <StylesProvider value={styles}>
        <Flex direction="column" gap="4px" w="full">
          {children}
        </Flex>
      </StylesProvider>
      {isDefined(onClose) && <CloseButton aria-label="Close" size="sm" onClick={onClose} />}
    </ChakraAlert>
  )
}

export const AlertTitle = (props: AlertTitleProps & React.ComponentProps<typeof ChakraAlertTitle>) => {
  const styles = useStyles()
  return <ChakraAlertTitle sx={styles.title} {...props} />
}

export const AlertDescription = (props: AlertChildrenProps) => {
  const styles = useStyles()
  return <ChakraAlertDescription sx={styles.description} {...props} />
}
