import { ApolloError } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { ActionButtons, Alert, Box, Button, Checkbox, Form, isDefined, PinInput, Text, VStack } from 'ui-lib'
import { z } from 'zod'

import { REMEMBER_ME_MFA_MAX_AGE_DAYS } from '@/src/constants/cookies'
import { useEffectOnce } from '@/src/hooks/misc'
import { usePersistentTimer } from '@/src/hooks/usePersistentTimer'

const schema = (pinLength: number = 4) =>
  z.object({
    pin: z.string().refine((v) => v.length === pinLength, `Pin must be ${pinLength} digits long`),
  })

type Props = {
  onSubmit: (pin: string) => Promise<any>
  onBack: () => Promise<any> | void
  sendCode: () => Promise<any>
  email: string
  title?: string
  backButtonText?: string
  onRememberMe?: (checked: boolean) => void
  pinLength?: number
}

export const EmailVerificationForm = (props: Props) => {
  const { control, handleSubmit, formState, setError } = useForm({
    defaultValues: {
      pin: '',
    },
    resolver: zodResolver(schema(props.pinLength ?? 4)),
  })

  const pinError = formState.errors.pin
  const onSubmit = async ({ pin }: { pin: string }) => {
    try {
      await props.onSubmit(pin)
    } catch (err: any) {
      let errorMessage = 'Invalid Pin'
      if (
        err instanceof ApolloError &&
        isDefined(err.graphQLErrors.find((e) => e.extensions?.code! === 'RATE_LIMIT'))
      ) {
        errorMessage = 'Max amount of attempts reached, please try again later'
      }
      setError('pin', { message: errorMessage })
    }
  }

  const { ready, start, difference } = usePersistentTimer(`email_verification_${props.email}`)

  const sendEmailCode = async () => {
    await props.sendCode()
    start(60)
  }

  useEffectOnce(() => {
    if (ready) {
      sendEmailCode()
    }
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <VStack flexDir="column" gap="6">
        <VStack alignItems={{ base: 'start', md: 'center' }} gap={2}>
          {isDefined(props.title) && (
            <Text
              textStyle="headline-4"
              textAlign={{
                base: 'start',
                md: 'center',
              }}
              as="h1"
            >
              {props.title}
            </Text>
          )}
          <Text
            textStyle="paragraph-4"
            textColor="text-secondary"
            textAlign={{
              base: 'start',
              md: 'center',
            }}
          >
            {`Please enter the ${props.pinLength}-digit PIN we sent to you by Email to ${props.email}.`}
          </Text>
        </VStack>

        <Controller
          control={control}
          name="pin"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <VStack id="email-verification-form-container">
              <PinInput onChange={onChange} value={value} otp isInvalid={isDefined(error)}>
                {Array.from({ length: props.pinLength ?? 4 }).map((_, index) => (
                  <PinInput.Field key={index} />
                ))}
              </PinInput>
            </VStack>
          )}
        />

        {isDefined(pinError) && <Alert variant="negative">{pinError?.message}</Alert>}

        <Box textAlign="center">
          {!ready && <Text textStyle="paragraph-4">Resend pin in {difference} seconds</Text>}
          {ready && (
            <Button variant="link" textStyle="paragraph-4" onClick={sendEmailCode}>
              Resend pin
            </Button>
          )}
        </Box>
        {isDefined(props.onRememberMe) && (
          <Checkbox
            onChange={(e) => {
              props.onRememberMe?.(e.target.checked)
            }}
            aria-label="Remember Device Toggle"
          >
            <Text fontStyle="paragraph-md" textColor="text-primary">
              Remember this device for {REMEMBER_ME_MFA_MAX_AGE_DAYS} days
            </Text>
          </Checkbox>
        )}
        <ActionButtons flexDirection="column">
          <ActionButtons.Secondary isDisabled={formState.isSubmitting} onClick={props.onBack} px={6} py={3} order={'1'}>
            {props.backButtonText ?? 'Back'}
          </ActionButtons.Secondary>
          <ActionButtons.Primary
            type="submit"
            isLoading={formState.isSubmitting}
            isDisabled={isDefined(formState.errors.pin)}
            px={6}
            py={3}
            order={'0'}
          >
            Continue
          </ActionButtons.Primary>
        </ActionButtons>
      </VStack>
    </Form>
  )
}
