import 'nprogress/nprogress.css'

import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'

export function ProgressBar() {
  const nextRouter = useRouter()

  useEffect(
    function loadProgressBar() {
      const handleStart = () => NProgress.start()

      const handleStop = () => NProgress.done()

      nextRouter.events.on('routeChangeStart', handleStart)
      nextRouter.events.on('routeChangeComplete', handleStop)
      nextRouter.events.on('routeChangeError', handleStop)

      return () => {
        nextRouter.events.off('routeChangeStart', handleStart)
        nextRouter.events.off('routeChangeComplete', handleStop)
        nextRouter.events.off('routeChangeError', handleStop)
      }
    },
    [nextRouter]
  )

  return (
    <style>
      {`
        #nprogress .bar {
          background: linear-gradient(236deg, #DB57B6 0%, #6497C7 100%);
        }

        #nprogress .spinner-icon {
          display: none;
        }
     `}
    </style>
  )
}
