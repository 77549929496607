import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['menuItem', 'icon', 'label', 'menuList', 'button'])

export const Menu = helpers.defineMultiStyleConfig({
  baseStyle: {
    button: {
      border: '1px solid',
      borderColor: 'transparent',
      transition: 'all 0.2s',
      borderRadius: 'md',
      _groupHover: { bg: 'bg-disabled' },
      px: {
        base: 3,
        md: 3,
      },
      width: 'full',

      _groupActive: {
        bg: 'active-item-gradient',
        borderColor: 'border-soft',
        borderRadius: 'md',
      },

      '[data-active] &': {
        bg: 'active-item-gradient',
        border: '1px solid',
        borderColor: 'border-soft',
        borderRadius: 'md',
      },
    },

    menuItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '10',
      px: 0,
      bg: 'transparent',
      color: 'text-soft',
      borderRadius: 'md',
      _hover: {
        bg: 'bg-disabled',
      },
    },

    icon: {
      width: 6,
      height: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    label: {
      ml: 3,
      transition: 'color 0.2s',
      _hover: {
        color: 'text-secondary',
      },
      '.parent-link:hover &': {
        color: 'text-secondary',
      },
    },

    rightElement: {
      ml: 2,
      justifySelf: 'flex-end',
    },

    menuList: {
      p: 0,
      mb: 4,
      ml: 2,
      mr: 2,
      minW: '252px',
      borderRadius: 'md',
      boxShadow: 'md',
      overflow: 'hidden',
    },
  },

  variants: {
    'active-open': {
      menuItem: {
        justifyContent: 'space-between',
        px: 2,
        color: 'text-primary',
        bg: 'active-item-gradient',
        border: '1px solid',
        borderColor: 'border-soft',
      },
      button: {
        px: {
          md: 4,
        },
      },
    },
    'active-collapsed': {
      menuItem: {
        color: 'text-primary',
        bg: 'active-item-gradient',
        border: '1px solid',
        borderColor: 'border-soft',
      },
    },
    'inactive-open': {
      menuItem: {
        justifyContent: 'space-between',
        px: 2,
      },
    },
    'user-open': {
      menuItem: {
        borderRadius: '0',
        height: '12',
        boxShadow: 'md',
        px: 4,
        transition: 'all 0.2s',
        color: 'text-soft',
        _notFirst: {
          borderTop: '1px solid',
          borderColor: 'border-soft',
        },
        _hover: {
          bg: 'bg-disabled',
          color: 'text-secondary',
        },
        _focus: {
          bg: 'bg-disabled',
        },
      },
    },
    'user-collapsed': {
      menuItem: {
        borderRadius: '0',
        height: '12',
        boxShadow: 'md',
        px: 4,
        transition: 'all 0.2s',
        color: 'text-soft',
        _notFirst: {
          borderTop: '1px solid',
          borderColor: 'border-soft',
        },
        _hover: {
          bg: 'bg-disabled',
          color: 'text-secondary',
        },
        _focus: {
          bg: 'bg-disabled',
        },
      },
    },
  },
})
