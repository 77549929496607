import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['container', 'label', 'closeButton', 'closeIcon'])

export const Tag = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      boxSizing: 'border-box',
      borderRadius: 'sm',
      alignItems: 'center',
      display: 'inline-flex',
    },
    label: {
      textStyle: 'text.sm.medium',
      color: 'inherit',
    },
    closeButton: {
      marginLeft: 2,
      height: 4,
      width: 4,
      minWidth: 4,
      opacity: 1,
      _hover: {
        opacity: 1,
      },
    },
    closeIcon: {
      height: 2,
      width: 2,
    },
  },
  sizes: {
    sm: {
      container: {
        borderRadius: 'xs',
      },
      label: {
        textStyle: 'subheading-sm',
      },
    },
    md: {},
  },
  variants: {
    neutral: {
      container: {
        background: 'bg-disabled',
        color: 'text-neutral',
        border: '1px solid',
        borderColor: 'border-neutral',
      },
      closeButton: {
        _hover: {
          background: '_primary.200',
        },
        _active: {
          background: '_primary.300',
        },
      },
      closeIcon: {
        color: '_primary.900',
      },
    },
    black: {
      container: {
        background: '_primary.900',
        color: 'basic.white',
        border: '1px solid var(--chakra-colors-_primary-900)',
      },
      closeButton: {
        _hover: {
          background: '_primary.800',
        },
        _active: {
          background: '_primary.700',
        },
      },
      closeIcon: {
        color: 'basic.white',
      },
    },
    lookAtMe: {
      container: {
        background: '_gradients.bluePink',
        color: 'basic.white',
      },
      closeIcon: {
        color: 'basic.white',
      },
    },
    positive: {
      container: {
        background: 'bg-success',
        color: 'text-success',
        border: '1px solid',
        borderColor: 'border-success',
      },
      closeButton: {
        _hover: {
          background: 'positive.100',
        },
        _active: {
          background: 'positive.200',
        },
      },
      closeIcon: {
        color: 'positive.700',
      },
    },
    info: {
      container: {
        background: 'accent.50',
        color: 'accent.600',
        border: '1px solid var(--chakra-colors-accent-100)',
      },
      closeButton: {
        _hover: {
          background: 'accent.100',
        },
        _active: {
          background: 'accent.200',
        },
      },
      closeIcon: {
        color: 'accent.600',
      },
    },
    blue: {
      container: {
        // this tag variant uses icon info as bg and border because there are no color token for their respective categories that match. It is an exception.
        background: 'icon-info',
        color: 'text-inverse',
        border: 'icon-info',
      },
      closeIcon: {
        color: 'icon-inverse',
      },
    },
    warning: {
      container: {
        background: 'warning.100',
        color: 'warning.900',
        border: '1px solid var(--chakra-colors-warning-200)',
      },
      closeButton: {
        _hover: {
          background: 'warning.200',
        },
        _active: {
          background: 'warning.300',
        },
      },
      closeIcon: {
        color: 'warning.900',
      },
    },
    negative: {
      container: {
        background: 'bg-error',
        color: 'text-error',
        border: '1px solid',
        borderColor: 'border-error',
      },
      closeButton: {
        _hover: {
          background: '_error.100',
        },
        _active: {
          background: '_error.200',
        },
      },
      closeIcon: {
        color: '_error.500',
      },
    },
    soft: {
      container: {
        background: 'bg-neutral',
        color: 'text-secondary',
        border: '1px solid',
        borderColor: 'border-neutral',
      },
      closeButton: {
        _hover: {
          background: 'bg-inverse',
        },
        _active: {
          background: 'bg-inverse',
        },
      },
      closeIcon: {
        color: 'text-neutral',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
})
