import { getCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { filterObjectEmptyValues, isDefined, utmParamsToSegment } from 'utils'

import { FACEBOOK_COOKIES, KeepCookie } from '@/src/constants/cookies'
import { analytics } from '@/src/utils/misc'

import { useInternalUser } from './use-internal-user'

const getUTMParams = () => {
  const cookie = getCookie(KeepCookie.ANALYTICS_UTM)
  const utmParams = cookie?.toString() ?? ''
  const utmParamsObject = utmParamsToSegment(utmParams, true)
  return utmParamsObject
}
const getFbp = () => {
  const cookie = getCookie(FACEBOOK_COOKIES._FBP)
  return cookie?.toString() ?? null
}

export const usePageSegmentAnalytics = () => {
  const router = useRouter()
  const user = useInternalUser()

  const fbp = useMemo(() => getFbp(), [])
  const utmParams = useMemo(() => getUTMParams(), [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      analytics.page(
        {
          page: url,
        },
        undefined,
        {
          queryParams: router.query,
          traits: {
            ...(isDefined(user)
              ? filterObjectEmptyValues({
                  email: user.email,
                  phone: user.phoneNumber,
                  firstName: user.firstName,
                  lastName: user.lastName,
                })
              : {}),
            ...(isDefined(fbp) ? { _fbp: fbp } : {}),
            ...utmParams,
          },
        }
      )
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => router.events.off('routeChangeComplete', handleRouteChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events, router.query])
}
