import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['outerContainer', 'container'])

export const Badge = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'white',
    },
    outerContainer: {
      position: 'absolute',
      top: '2',
      right: '7px',
      width: '7px',
      height: '7px',
      borderRadius: 'full',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '2.5',
      color: 'white',
    },
  },

  variants: {
    count: {
      container: {
        bg: 'icon-error',
        width: '4.5',
        height: '4.5',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        fontSize: '11px',
      },
      outerContainer: {
        bg: 'icon-error',
      },
    },
    new: {
      container: {
        bg: 'icon-info',
        height: '5',
        width: '42px',
        fontSize: 'xs',
        textTransform: 'none',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      outerContainer: {
        bg: 'icon-info',
      },
    },
  },
})
