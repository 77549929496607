import { HStack, PinInput as ChakraPinInput, PinInputField } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

type PinInputProps = React.ComponentProps<typeof ChakraPinInput> & {
  children?: React.ReactNode | React.ReactNode[]
}

export const PinInput = (props: PinInputProps) => (
  <HStack gap="0">
    <ChakraPinInput placeholder="" {...props} />
  </HStack>
)

type PinInputType = React.ComponentProps<typeof PinInputField>

const Field = forwardRef<HTMLInputElement, PinInputType>((props, ref) => (
  <PinInputField
    ref={ref}
    {...props}
    width="60px"
    fontSize="heading.h1"
    height="84px"
    borderColor="border-secondary"
    borderRadius="md"
    px="4"
    py="3"
    _focus={{
      borderColor: 'border-input-focus',
      boxShadow: 'input-focus',
    }}
  />
))

PinInput.Field = Field
