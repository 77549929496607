import { parseCookieString, extractQueryParams } from './chunk-5J5DU2QU.mjs';
import { filterObjectEmptyValues } from './chunk-OOKWXHOK.mjs';
import { isDefined } from './chunk-GVNWCKLH.mjs';

// helpers/utmParams.ts
var formatFbclid = (fbclid) => {
  if (!isDefined(fbclid) || fbclid === "") {
    return "";
  } else if (fbclid.startsWith("fb.1.")) {
    return fbclid;
  }
  const unixDate = Math.floor(Date.now() / 1e3);
  return `fb.1.${unixDate}.${fbclid}`;
};
var parseAnalyticsCookie = (cookies) => {
  const parsedCookies = parseCookieString(cookies ?? "");
  if (!isDefined(parsedCookies.ANALYTICS_UTM)) {
    return {};
  }
  return extractQueryParams(parsedCookies.ANALYTICS_UTM);
};
var utmParamsToSegment = (cookies, isCookieParsed = false) => {
  if (!isDefined(cookies)) {
    return {};
  }
  const utmParams = isCookieParsed ? extractQueryParams(cookies) : parseAnalyticsCookie(cookies);
  const context = Object.keys(utmParams).length > 0 ? {
    campaign: {
      source: utmParams["utm_source"],
      medium: utmParams["utm_medium"],
      name: utmParams["utm_campaign"],
      term: utmParams["utm_term"],
      content: utmParams["utm_content"]
    },
    clids: {
      gclid: utmParams["gclid"],
      fbclid: formatFbclid(utmParams["fbclid"]),
      ttclid: utmParams["ttclid"],
      twclid: utmParams["twclid"]
    }
  } : {};
  const filteredParams = filterObjectEmptyValues(context);
  return filteredParams;
};

export { parseAnalyticsCookie, utmParamsToSegment };
