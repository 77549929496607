import Link from 'next/link'
import { Text, VStack } from 'ui-lib'

import { MfaChannelEnum } from '@/gql'

import { EmailVerificationForm } from './email-verification-form'
import { PhoneVerificationForm } from './phone-verification-form'

type Props = {
  verificationChannel: MfaChannelEnum
  data: {
    email?: string | null
    phoneNumber?: string | null
  }
  onSubmit: (pin: string) => Promise<void>
  onBack: () => void
  sendCode: () => Promise<void>

  onRememberMe?: (checked: boolean) => void
  showHelp?: boolean
  backButtonText?: string
  pinLength?: number
}

export const VerificationForm = ({
  verificationChannel,
  data,
  onSubmit,
  onBack,
  sendCode,
  backButtonText,
  onRememberMe,
  showHelp = false,
  pinLength = 4,
}: Props) => {
  return (
    <VStack gap={4}>
      {verificationChannel === MfaChannelEnum.SmsOtp && (
        <PhoneVerificationForm
          title="Enter Verification Code"
          user={{
            phoneNumber: data.phoneNumber ?? '',
          }}
          onSubmit={onSubmit}
          onBack={onBack}
          sendCode={sendCode}
          backButtonText={backButtonText}
          onRememberMe={onRememberMe}
          pinLength={pinLength}
        />
      )}

      {verificationChannel === MfaChannelEnum.EmailOtp && (
        <EmailVerificationForm
          title="Enter Verification Code"
          email={data.email ?? ''}
          onSubmit={onSubmit}
          onBack={onBack}
          sendCode={sendCode}
          backButtonText={backButtonText}
          onRememberMe={onRememberMe}
          pinLength={pinLength}
        />
      )}
      {showHelp && (
        <Text>
          Having trouble?{' '}
          <Text
            fontStyle="paragraph-md"
            textColor="text-primary"
            textDecor="underline"
            fontWeight="medium"
            as={Link}
            href={'/assistance'}
            target="blank"
          >
            Get Help
          </Text>
        </Text>
      )}
    </VStack>
  )
}
