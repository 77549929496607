import { FetchResult } from '@apollo/client'
import { StatsigFeatureGate } from '@try-keep/feature-flag'
import { hasCookie } from 'cookies-next'
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDisclosure } from 'ui-lib'
import { isDefined } from 'utils'

import {
  SecureActionScopeEnum,
  useStartSecureActionChallengeMutation,
  useVerifySecureActionChallengeMutation,
} from '@/gql'
import { SecureActionModal } from '@/src/components/mfa/secure-action-modal'
import { KeepCookie } from '@/src/constants/cookies'

import { useFeatureGate } from '../misc'
import { SecureActionError } from './errors'
import { Mutation, MutationArgs, SecureActionModalResult, UseSecureActionProps, UseSecureActionResult } from './types'

interface ContextType {
  secureAction: SecureActionScopeEnum | null
  setSecureAction: (action: SecureActionScopeEnum | null) => void
  startSecureAction: <T, K>(mutation: Mutation<T, K>, args: MutationArgs<T, K>) => Promise<FetchResult<T> | undefined>
  verifySecureAction: (pin: string) => Promise<boolean>
}
export const SecureActionsContext = createContext<ContextType | null>(null)

export type SecureActionsProviderProps = PropsWithChildren
export const SecureActionsProvider = ({ children }: SecureActionsProviderProps) => {
  const isSecureActionTokenPresent = hasCookie(KeepCookie.SECURE_ACTION_TOKEN)
  const { onClose, onOpen, isOpen } = useDisclosure()
  const [secureAction, setSecureAction] = useState<SecureActionScopeEnum | null>(null)
  const [pendingMutation, setPendingMutation] = useState<(() => Promise<FetchResult<unknown> | null>) | null>(null)
  const [modalCloseRejection, setModalCloseRejection] = useState<(() => void) | null>(null)
  const [verifySecureActionChallenge] = useVerifySecureActionChallengeMutation()
  const isSecureActionFFEnabled = useFeatureGate(StatsigFeatureGate.SECURED_MUTATIONS)

  // This is a callback that will be called when the secure action is finished
  const onSecureActionFinished = useCallback(() => {
    setPendingMutation(null)
    setSecureAction(null)
    onClose()
  }, [onClose])

  const onModalClosed = useCallback(() => {
    if (isDefined(modalCloseRejection)) {
      modalCloseRejection()
    }
    onSecureActionFinished()
  }, [modalCloseRejection, onSecureActionFinished])

  const startSecureAction = useCallback(
    async <T, K>(mutation: Mutation<T, K>, args: MutationArgs<T, K>): Promise<FetchResult<T>> => {
      if (!isDefined(secureAction) || !isDefined(mutation)) {
        onSecureActionFinished()
        throw new Error('Secure action is not set')
      }
      // If the secure action is not enabled, or the secure action token is present, we can skip the secure action process
      if (isSecureActionTokenPresent || !isSecureActionFFEnabled) {
        return mutation(args)
      }

      // This is a promise that will resolve when the secure action is verified
      return new Promise<FetchResult<T>>((resolve, reject) => {
        setPendingMutation(() => async () => {
          try {
            const result = await mutation(args)
            resolve(result)
            return result
          } catch (error) {
            reject(error)
            return null
          }
        })
        setModalCloseRejection(() => () => {
          reject(new SecureActionError('Modal closed without verifying secure action'))
        })
        onOpen()
      })
    },
    [secureAction, isSecureActionTokenPresent, onSecureActionFinished, onOpen, isSecureActionFFEnabled]
  )

  const verifySecureAction = useCallback(
    async (pin: string) => {
      if (!isDefined(secureAction) || !isDefined(pendingMutation)) {
        onSecureActionFinished()
        throw new Error('Secure action is not set')
      }

      const verificationResult = await verifySecureActionChallenge({
        variables: {
          body: { scope: secureAction, code: pin },
        },
      })

      const verified = Boolean(verificationResult.data?.verifySecureActionChallenge)

      if (verified) {
        // If the secure action is verified, we can safely close the modal and then execute the mutation
        onSecureActionFinished()
        await pendingMutation()
      }

      return verified
    },
    [secureAction, pendingMutation, verifySecureActionChallenge, onSecureActionFinished]
  )

  const value = useMemo(
    () => ({
      secureAction,
      setSecureAction,
      startSecureAction,
      verifySecureAction,
    }),
    [secureAction, startSecureAction, verifySecureAction]
  )

  return (
    <SecureActionsContext.Provider value={value}>
      <SecureActionModal isOpen={isOpen} onClose={onModalClosed} />
      {children}
    </SecureActionsContext.Provider>
  )
}

/**
 * This hook is used to start the secure action challenge and verify the code from the secure action modal
 * Verifies if the code provided is correct and safe to execute the action
 * @param mutation - The mutation to be executed
 * @param action - The action to be executed
 * @returns A tuple containing the start secure action function and the mutation result
 */
export function useSecureAction<T, K>({ mutation, action }: UseSecureActionProps<T, K>): UseSecureActionResult<T, K> {
  const context = useContext(SecureActionsContext)
  if (!isDefined(context)) {
    throw new Error('useSecureAction must be used within a SecureActionsProvider')
  }
  const [startMutation, mutationResult] = mutation

  //Set the secure action when the component mounts and clean it up when the component unmounts
  useEffect(() => {
    if (isDefined(action)) {
      context.setSecureAction(action)
    }
    return () => {
      context.setSecureAction(null)
    }
  }, [action, context])

  return [(args: MutationArgs<T, K>) => context.startSecureAction<T, K>(startMutation, args), mutationResult]
}

/**
 * This hook is used to start the secure action challenge and verify the code from the secure action modal
 * @returns An object containing the start secure action function, verify secure action function, and the secure action
 */
export function useSecureActionModal(): SecureActionModalResult {
  const context = useContext(SecureActionsContext)
  if (!isDefined(context)) {
    throw new Error('useSecureActionModal must be used within a SecureActionsProvider')
  }
  const [startSecureActionChallenge] = useStartSecureActionChallengeMutation()

  async function startSecureAction() {
    const scope = context?.secureAction

    if (!isDefined(scope)) {
      throw new Error('Secure action is not set')
    }

    await startSecureActionChallenge({
      variables: {
        body: { scope },
      },
    })
  }

  return {
    startSecureAction,
    verifySecureAction: context.verifySecureAction,
    secureAction: context.secureAction,
  }
}
