import { ComponentProps } from 'react'
import { G, Path, Svg } from 'ui-lib'

const CardIcon = (props?: ComponentProps<typeof Svg>) => {
  return (
    <Svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      marginTop={'1px'}
      {...props}
    >
      <G clipPath="url(#clip0_2605_13802)">
        <Path
          d="M15.5007 3.33398H2.83398C1.72941 3.33398 0.833984 4.22941 0.833984 5.33398V10.0007V14.6673C0.833984 15.7719 1.72941 16.6673 2.83398 16.6673H15.5007C16.6052 16.6673 17.5007 15.7719 17.5007 14.6673V5.33398C17.5007 4.22941 16.6052 3.33398 15.5007 3.33398Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3.83398"
          y="7.16602"
          width="2.33333"
          height="2.33333"
          rx="0.5"
          fill="currentColor"
          stroke="currentColor"
        />
      </G>
      <defs>
        <clipPath id="clip0_2605_13802">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default CardIcon
