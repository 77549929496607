import React from 'react'

import { logger } from '@/src/logging/logger'

import { ErrorMessage } from './error-message'

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
  retryCount: number
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined,
      retryCount: 0,
    }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error,
      retryCount: 0,
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logger.error('Error caught by boundary:', {
      error,
      errorInfo,
      stack: error.stack,
    })
  }

  private handleReset = (): void => {
    const newRetryCount = this.state.retryCount + 1

    if (newRetryCount >= 3) {
      // After 3 retries, redirect to home page
      window.location.href = '/'
      return
    }

    this.setState({
      hasError: false,
      error: undefined,
      retryCount: newRetryCount,
    })
  }

  render(): React.ReactNode {
    const { hasError } = this.state

    if (hasError) {
      return (
        <ErrorMessage error={this.state.error} onHandleError={this.handleReset}>
          {({ getButtonProps }) => <ErrorMessage.ActionButton {...getButtonProps()} />}
        </ErrorMessage>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
