import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const helpers = createMultiStyleConfigHelpers(['header', 'dialog', 'body', 'footer', 'toggleButton', 'logo'])

export const Sidebar = helpers.defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      bg: mode('white', 'primary.500'),
    },

    header: {
      alignItems: 'center',
      p: '2',
      borderBottomWidth: '1px',
    },

    logo: {
      height: '8',
      width: '29px',
      opacity: '_groupHover.opacity.0',
      transition: 'opacity 0.2s',
    },

    toggleButton: {
      opacity: {
        base: 0,
        lg: 0,
      },
      _groupHover: { opacity: 1 },
      transition: 'opacity 0.2s',
      bg: 'bg-disabled',
      position: 'absolute',
      left: 0,
      top: 0,
    },

    body: {
      flex: '1',
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'flex',
      height: 'full',
      flexDirection: 'column',
      justifyContent: 'space-between',
      p: '3',
    },

    footer: {
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: '1px',
      borderColor: 'border-disabled',
      w: 'full',
      height: '90px',
    },
  },
  variants: {
    open: {
      logo: {
        height: '8',
        width: '110px',
        opacity: 1,
        transition: 'opacity 0.2s',
      },
      toggleButton: {
        opacity: {
          base: 0,
          lg: 1,
        },
        _groupHover: { opacity: 1 },
        transition: 'opacity 0.2s',
        position: 'relative',
      },
    },
  },
})
