import { useSession } from 'next-auth/react'
import { Modal } from 'ui-lib'

import { MfaChannelEnum } from '@/gql'
import { useSecureActionModal } from '@/src/contexts/secure-actions/secure-actions-context'

import { VerificationForm } from '../verification/verification-form'

type SecureActionModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const SecureActionModal = ({ isOpen, onClose }: SecureActionModalProps) => {
  const { data } = useSession()
  const { verifySecureAction, startSecureAction } = useSecureActionModal()

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <Modal.Content padding="0" data-testid="secure-action-modal" maxW={'450px'}>
        <Modal.CloseButton />

        <Modal.Body>
          <VerificationForm
            verificationChannel={
              (data?.user?.internal?.mfaSettings.find((mfaSetting) => mfaSetting.isPrimary)?.channel ??
                MfaChannelEnum.EmailOtp) as MfaChannelEnum
            }
            data={{ email: data?.user?.email, phoneNumber: data?.user?.internal?.phoneNumber }}
            onSubmit={async (pin) => {
              await verifySecureAction(pin)
            }}
            onBack={onClose}
            sendCode={startSecureAction}
            backButtonText="Cancel"
            pinLength={6}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
