import { ComponentProps } from 'react'
import { Path, Svg } from 'ui-lib'

const HomeIcon = (props?: ComponentProps<typeof Svg>) => {
  return (
    <Svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      marginTop="1px"
      {...props}
    >
      <Path
        d="M12.5 18.3329H9.99935H7.49935M12.5 18.3329H16.666C17.108 18.3329 17.532 18.1573 17.8445 17.8447C18.1571 17.5322 18.3327 17.1083 18.3327 16.6662V10.1446C18.3327 9.69083 18.2401 9.24186 18.0606 8.82517C17.8811 8.40847 17.6183 8.03282 17.2885 7.72122L11.1443 1.91456C10.8349 1.62199 10.4252 1.45898 9.99935 1.45898C9.5735 1.45898 9.1638 1.62199 8.85435 1.91456L2.71018 7.72122C2.38037 8.03282 2.11764 8.40847 1.9381 8.82517C1.75855 9.24186 1.66597 9.69083 1.66602 10.1446V16.6662C1.66602 17.1083 1.84161 17.5322 2.15417 17.8447C2.46673 18.1573 2.89065 18.3329 3.33268 18.3329H7.49935M12.5 18.3329V12H9.99935H7.49935V18.3329"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default HomeIcon
