const knownTruliooErrors = [
  'Already resumed',
  'video element not found',
  'WebGLShader',
  'SolutionWasm',
  'abort(undefined) at Error',
]

const globalKnownErrors = [
  '_AutofillCallbackHandler',
  'ResizeObserver loop completed',
  'l.didTimeout',
  "Can't find variable: gmo",
  "Cannot read properties of undefined (reading 'domInteractive')",
]

const knownErrorMessages = [...knownTruliooErrors, ...globalKnownErrors]

export function isKnownError(err: any): boolean {
  // Third party throwing "Timeout" most likely related to google recaptcha
  // https://github.com/getsentry/sentry-javascript/issues/2514
  if (typeof err === 'string' && err === 'Timeout') {
    return true
  }

  if (err instanceof Error && knownErrorMessages.some((m) => err.message?.includes?.(m))) {
    return true
  }

  return false
}
