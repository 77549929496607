import { useEffect, useState } from 'react'
import { isDefined } from 'ui-lib'

import { date } from '../utils/date'

export function usePersistentTimer(sessionStorageKey: string) {
  const [currentTime, setCurrentTime] = useState(() => date())
  const [targetTime, setTargetTime] = useState(() => {
    const t = sessionStorage.getItem(sessionStorageKey)
    if (isDefined(t)) {
      return date(t)
    }
    return null
  })

  let ready = true
  let diff = 0

  if (isDefined(targetTime) && currentTime.isBefore(targetTime)) {
    ready = false
    diff = targetTime!.diff(currentTime, 'seconds')
  }

  const start = (seconds: number) => {
    const target = date().add(seconds, 'seconds').endOf('second')
    sessionStorage.setItem(sessionStorageKey, target.toISOString())
    setCurrentTime(date())
    setTargetTime(target)
  }

  useEffect(() => {
    if (isDefined(targetTime) && !ready) {
      const interval = setInterval(() => {
        setCurrentTime(date().endOf('second'))
      }, 500)

      return () => clearInterval(interval)
    }
  }, [targetTime, ready])

  return {
    ready,
    difference: diff,
    start,
  }
}
