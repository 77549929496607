import {
  ApolloClient,
  createHttpLink,
  DefaultContext,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import merge from 'deepmerge'
import isEqual from 'lodash.isequal'
import { getSession } from 'next-auth/react'
import { useMemo } from 'react'
import { useIntervalWhen } from 'rooks'
import { isDefined } from 'utils'

import { GRAPHQL_SERVER_URL, SHOULD_CONNECT_TO_APOLLO_DEV_TOOLS } from '@/src/utils/misc'

const APOLLO_CACHE_KEY = '__APOLLO_STATE__'

let cachedApolloClient: ApolloClient<unknown>
const IS_SERVER_SIDE = typeof window === 'undefined'

const getAccessToken = (ctx: DefaultContext & { accessToken?: string }) => {
  const hasAccessTokenFromContext = isDefined(ctx.accessToken)

  if (hasAccessTokenFromContext) {
    return ctx.accessToken
  }

  return getSession().then((session) => session?.accessToken)
}

export const createApolloClient = (graphqlUrl: string, defaultContext?: Partial<DefaultContext>) => {
  const httpLink = createHttpLink({
    uri: graphqlUrl,
    credentials: 'include',
  })

  const authLink = setContext(async (_, context) => {
    const { headers } = context as { headers: Headers }
    const accessToken = await getAccessToken(context)

    const modifiedHeader = {
      headers: {
        ...headers,
        authorization: isDefined(accessToken) ? `Bearer ${accessToken}` : '',
      },
    }
    return modifiedHeader
  })

  const client = new ApolloClient({
    ssrMode: IS_SERVER_SIDE,
    link: from([authLink, httpLink]),
    cache: new InMemoryCache({
      /**
       * Configures Apollo Client's InMemoryCache to prevent unnecessary
       * cache warnings and avoid redundant network requests.
       *
       * - `merge: true` ensures Apollo merges incoming objects instead of replacing them.
       * - Helps maintain cache consistency when objects are updated.
       * - Prevents "Cache data may be lost" warnings by properly handling nested fields.
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior#merging-non-normalized-objects
       */
      typePolicies: {
        FundingAccountWithDetails: { fields: { bankAccount: { merge: true } } },
        Wallet: { fields: { balance: { merge: true } } },
      },
    }),
    connectToDevTools: SHOULD_CONNECT_TO_APOLLO_DEV_TOOLS,
    defaultContext,
  })

  return client
}

function initializeApollo(initialState: unknown = undefined, defaultContext?: Partial<DefaultContext>) {
  const _apolloClient = cachedApolloClient ?? createApolloClient(GRAPHQL_SERVER_URL, defaultContext)

  // The initial state gets hydrated here
  if (isDefined(initialState)) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = isDefined(cachedApolloClient) ? cachedApolloClient.extract() : {}
    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache as any, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })
    // Restore the cache with the merged data

    _apolloClient.cache.restore(data as NormalizedCacheObject)
  }

  // For SSG and SSR there's no need to cache the apollo client
  if (IS_SERVER_SIDE) {
    return _apolloClient
  }

  // Create Apollo Client once in the client
  if (!isDefined(cachedApolloClient)) {
    cachedApolloClient = _apolloClient
  }

  return cachedApolloClient
}

export const initializeServerApolloClient = (accessToken: string | null) => initializeApollo(undefined, { accessToken })

export function addApolloState<T extends { props: {} }>(
  client: ApolloClient<unknown>,
  pageProps: T = { props: {} } as T
) {
  const props = {
    ...pageProps.props,
    [APOLLO_CACHE_KEY]: client.cache.extract(),
  }
  pageProps.props = props
  return pageProps
}

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

export function useApollo(pageProps?: Record<typeof APOLLO_CACHE_KEY, unknown>) {
  const client = useMemo(() => initializeApollo(pageProps?.[APOLLO_CACHE_KEY]), [pageProps?.[APOLLO_CACHE_KEY]])

  useIntervalWhen(() => {
    client.resetStore()
  }, FIVE_MINUTES_IN_MS)

  return client
}
