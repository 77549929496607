// helpers/cookies.ts
var parseCookieString = (cookieString) => {
  const cookiePairs = cookieString.split(";");
  const cookieObject = {};
  cookiePairs.forEach((pair) => {
    const [key, value] = pair.trim().split("=");
    cookieObject[key] = value;
  });
  return cookieObject;
};
var extractQueryParams = (queryParams) => {
  let queryString = decodeURIComponent(queryParams.replace(/\+/g, " "));
  queryString = queryString.startsWith("?") ? queryString.slice(1) : queryString;
  const params = new URLSearchParams(queryString).entries();
  return Object.fromEntries(params);
};

export { extractQueryParams, parseCookieString };
