import {
  IconButton,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useModalContext,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import React from 'react'

import { Icon } from '../icon'

type ModalProps = Omit<React.ComponentProps<typeof ChakraModal>, 'variant'>

// Trap focus is disabled in cypress to avoid race condition errors
const isCypress: boolean = typeof window !== 'undefined' && (window as any).Cypress
const enableTrapFocus = !isCypress

export const Modal = ({ children, ...rest }: ModalProps) => {
  return (
    <ChakraModal {...rest} variant="new" trapFocus={enableTrapFocus}>
      <ModalOverlay bg="rgba(24, 28, 32, 0.7)" backdropFilter="blur(10px)" />
      {children}
    </ChakraModal>
  )
}

const CloseButton = () => {
  const { onClose } = useModalContext()
  const styles = useMultiStyleConfig('Modal', { variant: 'new' })
  return (
    <IconButton
      onClick={onClose}
      variant="link"
      aria-label="Close modal"
      icon={<Icon size="md" variant="primary" icon="cross" />}
      sx={styles.closeButton}
    />
  )
}

Modal.Content = ModalContent
Modal.Header = ModalHeader
Modal.Footer = ModalFooter
Modal.Body = ModalBody
Modal.CloseButton = CloseButton
