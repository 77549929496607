import { Button, ButtonProps, Card, Heading, Text, VStack } from 'ui-lib'
import { isDefined } from 'utils'

interface ErrorMessageProps {
  error?: Error
  onHandleError?: () => void
  children: (props: { getButtonProps: (props?: ButtonProps) => ButtonProps }) => React.ReactNode
}

export const ErrorMessage = ({ error, onHandleError, children }: ErrorMessageProps) => {
  const getButtonProps = (props: ButtonProps = {}): ButtonProps => ({
    onClick: onHandleError,
    colorScheme: 'blue',
    size: 'lg',
    width: 'full',
    _hover: { transform: 'translateY(-1px)' },
    transition: 'all 0.2s',
    isDisabled: !isDefined(onHandleError),
    ...props,
  })

  return (
    <Card p={8} maxW="md" mx="auto" mt={8} boxShadow="lg">
      <VStack spacing={6} align="center">
        <Heading size="lg" textAlign="center" color="text-primary">
          Oops! Something went wrong
        </Heading>

        <Text textAlign="center" color="text-secondary">
          {isDefined(error) && isDefined(error.message)
            ? error.message
            : "We're having trouble loading this page. Please try again."}
        </Text>

        {children({ getButtonProps })}
      </VStack>
    </Card>
  )
}

ErrorMessage.ActionButton = function ActionButton({ label = 'Try Again', ...props }: ButtonProps & { label?: string }) {
  return <Button {...props}>{label}</Button>
}
