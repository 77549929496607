import React, { ComponentProps } from 'react'

import { Path, Svg } from '../components/chakra-react'

const GiftIcon = (props?: ComponentProps<typeof Svg>) => (
  <Svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M9 8C7.34315 8 6 6.65685 6 5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5M12 5C12 3.34315 13.3431 2 15 2C16.6569 2 18 3.34315 18 5C18 6.65685 16.6569 8 15 8M12 5L12 22M21 15H3M5 22H19C20.1046 22 21 21.1046 21 20V10C21 8.89543 20.1046 8 19 8H5C3.89543 8 3 8.89543 3 10V20C3 21.1046 3.89543 22 5 22Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default GiftIcon
